<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div
      class="uk-width-1-1 uk-flex uk-flex-column-sm uk-flex-column-md uk-flex-row uk-flex-between uk-flex-middle"
    >
      <div class="uk-width-1-1 uk-width-auto@l">
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
      <div class="uk-width-1-1 uk-width-auto@l uk-margin-small-top uk-margin-remove-top-lg uk-margin-remove-top-xl">
        <button
          v-if="isCanAccessUser(`add`, `EXPORT HUB`)"
          class="uk-button green uk-width-1-1"
          @click.prevent="$router.push({ name: 'ExportHubCreate' })"
        >
          <img
            :src="`${images}/icon/ic_plus_white.svg`"
            class="uk-margin-small-right"
            alt="ic_plus"
            loading="lazy"
          >
          Add Export Hub
        </button>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <header-export-hub
        :images="images"
        :filter-export-hub="filterExportHub"
        :reset-export-hub="resetExportHub"
        :list-regions="listRegions"
        :meta-filter="metaFilter"
      />
      <content-export-hub
        :images="images"
        :list-export-hub="listExportHub"
      />
      <pagination
        :total-data="totalData"
        :change-limit="changeLimit"
        :change-page="changePage"
        :current-page="currentPage"
        :current-limit="currentLimit"
      />
    </div>
    <modal-add-edit-discard
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import HeaderExportHub from './HeaderExportHub'
import ContentExportHub from './ContentExportHub'
import Pagination from '@/components/globals/Pagination'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'
import getHeaderTitle from '@/utils/header-title'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderExportHub,
    ContentExportHub,
    Pagination,
    ModalAddEditDiscard
  },
  props: {
    dataRole: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10,
      loadingType: null,
      filterRole: {}
    }
  },
  computed: {
    ...mapGetters({
      listRegions: 'regions/list_regions',
      listExportHub: 'exportHub/list_export_hub',
      metaFilter: 'exportHub/meta_filter',
      getDataModalAddEdit: 'exportHub/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'exportHub/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    listExportHub(data) {
      this.totalData = data.meta.total
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      setTimeout(() => {
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      }, 2000)
    }
  },
  async mounted() {
    this.filterRole = this.dataRole.find((item) => item.name.split(" ").join(" ").toUpperCase() == 'EXPORT HUB')
    await this.actionGetListRegions()
    this.loadPage()
  },
  methods: {
    ...mapActions({
      actionGetListRegions: 'regions/getListRegions',
      actionGetListExportHub: 'exportHub/getListExportHub',
      actionDeleteExportHub: 'exportHub/deleteExportHub'
    }),
    ...mapMutations({
      setMetaFilter: "exportHub/SET_META_FILTER",
      setToggleModalAddEditDiscard: 'exportHub/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async loadPage() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      if(this.metaFilter == undefined) {
        await this.actionGetListExportHub({ page: this.currentPage, limit: this.currentLimit})
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      } else {
        if (Object.keys(this.metaFilter).length > 0) {
          this.currentPage = parseInt(this.metaFilter.page) || 1
          await this.actionGetListExportHub({ ...this.metaFilter, page: this.currentPage, limit: this.currentLimit})
          this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
        } else {
          await this.actionGetListExportHub({ page: this.currentPage, limit: this.currentLimit })
          this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
        }
      }
    },
    async filterExportHub() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListExportHub({...this.metaFilter, sort_by: 'regions.name', page: 1})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async resetExportHub() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = 1
      this.currentLimit = 10

      this.setMetaFilter({
        search: undefined,
        region: undefined,
        status: undefined
      })
      await this.actionGetListExportHub({ ...this.metaFilter, page: this.currentPage, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changePage(page) {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = parseInt(page)
      await this.actionGetListExportHub({...this.metaFilter, page, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListExportHub({...this.metaFilter, limit: event.target.value, page: 1})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    handleModalDelete(){
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        loadingTable: false,
        loadingPopUp: true,
        id: this.getToggleModalAddEditDiscard.id
      })
      this.actionDeleteExportHub(this.getToggleModalAddEditDiscard.id).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: true,
              loadingPopUp: false
            })
            notificationSuccess('Delete Export Hub Successful')
          }, 500)
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            this.loadPage()
          }, 1000)
        }
      })
    }
  }
}
</script>
