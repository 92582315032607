<template>
  <table-content-export-hub
    :images="images"
    :list-export-hub="listExportHub"
    :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
    :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
  />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import TableContentExportHub from '@/components/globals/table/facility/export-hub/index'

export default {
  components: {
    TableContentExportHub
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listExportHub: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'exportHub/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'exportHub/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
